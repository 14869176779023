import React from 'react';
import * as styles from './footer.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import useResponsive from '@src/utils/useResponsive';
import Contacts from '@components/molecules/Contacts';
import SubscribeForm from '@components/molecules/SubscribeForm';
import Socials from '@components/molecules/Socials';
import Logo from '@src/assets/images/landing/Logo.svg';
import XLogo from '@src/assets/images/landing/Xplay.svg';
import { links } from '@src/constants';

const MenuItem = ({ title, children, className }) => {
	const classes = [styles.menuItem, className].join(' ');

	return (
		<div className={classes}>
			<span className={styles.menuItemTitle}>{title}</span>
			{children}
		</div>
	);
};

const Footer = ({ showRubPaymentSiteVersion }) => {
	const intl = useIntl();
	const [isDesktop, isTablet, isMobile] = useResponsive();

	return (
		<footer className={styles.container}>
			<a href="/" className={styles.logo}>
				<img src={Logo} alt="SCOPE.GG LOGO" />
				<span>Feel the game</span>
			</a>

			<div className={styles.mobileContacts}>
				<Contacts />
			</div>

			<ul className={styles.menu}>
				<MenuItem title={intl.formatMessage({ id: 'landing.menu.help' })}>
					<ul className={styles.links}>
						<li className={styles.linksItem}>
							<a href={links.guides[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.guides' })}
							</a>
						</li>
						<li className={styles.linksItem}>
							<a href={links.blog[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.blog' })}
							</a>
						</li>
						<li className={styles.linksItem} style={{ textTransform: 'uppercase' }}>
							<a href={links.faq[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.faq' })}
							</a>
						</li>
					</ul>
				</MenuItem>

				<MenuItem title={intl.formatMessage({ id: 'landing.menu.legal' })}>
					<ul className={styles.links}>
						<li className={styles.linksItem}>
							<a href={links.cookies[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.cookies' })}
							</a>
						</li>
						<li className={styles.linksItem}>
							<a href={links.privacy[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.privacy' })}
							</a>
						</li>
						<li className={styles.linksItem}>
							<a href={links.tou[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.tou' })}
							</a>
						</li>
					</ul>
				</MenuItem>

				<MenuItem title={intl.formatMessage({ id: 'landing.menu.product' })}>
					<ul className={styles.links}>
						<li className={styles.linksItem}>
							<a href={links.grenades[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.grenades' })}
							</a>
						</li>
						<li className={styles.linksItem}>
							<a href={links.strategy[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.board' })}
							</a>
						</li>
						{/* <li className={styles.linksItem}>
							<a href={links.team[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.team' })}
							</a>
						</li> */}
						<li className={styles.linksItem}>
							<a href={links.comparing[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.comparing' })}
							</a>
						</li>
						<li className={styles.linksItem}>
							<a href={links.faceitStats[intl.locale]} target="_blank">
								{intl.formatMessage({ id: 'landing.menu.faceit' })}
							</a>
						</li>
					</ul>
				</MenuItem>
				<MenuItem title={intl.formatMessage({ id: 'landing.menu.contactUs' })} className={styles.contacts}>
					<Contacts />
				</MenuItem>
			</ul>

			<p className={styles.xPlay}>
				{!isMobile ? (
					<a href="https://excorp.gg" target="_blank" className={styles.xPlayLink}>
						<img src={XLogo} alt="EXCORP.GG LOGO" />
					</a>
				) : (
					<span className={styles.copy}>© 2025 SCOPE.GG</span>
				)}
				{!!showRubPaymentSiteVersion ? (
					<>
						ИП Нахибашев Омаргаджи Муслимович
						<br />
						ОГРНИП 322057100068677, ИНН 054521628700
					</>
				) : (
					<>
						CS Virtual Trade Ltd, reg. no. HE 389299
						<br />
						Address: 705, Spyrou Araouzou & Koumantarias, Fayza House, 3036, Limassol, Cyprus
					</>
				)}
			</p>

			{/*<MenuItem title={intl.formatMessage({ id: 'landing.menu.followTheUpdate' })} className={styles.formWrapper}>*/}
			{/*	<SubscribeForm />*/}
			{/*</MenuItem>*/}

			<div className={styles.socials}>
				<Socials />
				{!isMobile ? <p className={styles.copy}>© 2025 SCOPE.GG</p> : null}
			</div>
		</footer>
	);
};

export default Footer;
